.bottombar {
  position: fixed;
  bottom: 0;
  height: 60px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-top: 1px solid #dddddd;
  background-color: white;
}

.bottombar_left {
  width: 33.3%;
  height: 100%;
  border-right: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bottombar_middle {
  width: 33.3%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.bottombar_right {
  width: 33.3%;
  height: 100%;
  border-left: 1px solid #dddddd;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
