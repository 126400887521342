.home {
  padding-bottom: 80px;
  width: 100%;
  padding: 10px;
}

.home_title {
  /* Add your styles here */
  font-weight: 600;
  font-size: 36px;
  padding-bottom: 36px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 18px;
}

.home_card {
  /* Add your styles here */
  width: 100%;
  border: 1px solid #dddddd;
  box-shadow: 0px 1px 6px 12px #00000014;
  margin-bottom: 24px;
  border-radius: 24px;
  padding-bottom: 24px;
  padding-top: 10px;
}

.home_card_top {
  /* Add your styles here */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 14px;
}

.home_card_top_img {
  /* Add your styles here */
  background-color: grey;
  width: 30vw;
  height: 30vw;
  border-radius: 1000px;
  object-fit: cover;
}

.home_card_top_name {
  /* Add your styles here */
  font-weight: 500;
  font-size: 24px;
  padding-top: 10px;
  padding-bottom: 10px;
}

.home_card_bottom {
  /* Add your styles here */
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.home_card_bottom_task {
  /* Add your styles here */
  font-weight: 500;
  font-size: 18px;
}

.home_card_bottom_task_started {
  /* Add your styles here */
  color: red;
}

.home_card_bottom_task_endbutton {
  /* Add your styles here */
  width: calc(100% - 28px);
  border-radius: 24px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #006aff;
  margin-top: 24px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}

.button {
  /* Add your styles here */
  transition: transform 0.3s;
  cursor: pointer;
}

.button:hover {
  background-color: #f3f3f3;
}

.button:active {
  transform: scale(0.6);
}

.home_newTodo_title {
  /* Add your styles here */
}

.home_newTodo_textbox {
  /* Add your styles here */
}

.home_newTodo_textbox_input {
  /* Add your styles here */
  border: 1px solid #dddddd;
  height: 100px;
  padding: 30px;
  width: calc(100% - 28px);
  border-radius: 24px;
}

.home_newTodo_upload {
  /* Add your styles here */
  width: calc(100% - 28px);
  border-radius: 24px;
  height: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #006aff;
  margin-top: 24px;
  color: white;
  font-size: 18px;
  font-weight: 600;
}
